import { types } from "../../types";

const initialState = {
    pagination: {
        data: [],
        per_page: 25,
    },
    paginationStudents: {
        data: [],
        per_page: 25,
    },
    paginationSchoolsOfOrgin: {
        data: [],
        per_page: 25,
        total: 0,
    },
    cycleStudentsReport: {
        data: [],
        count: 0,
        cycles: [],
    },
    condensedSchoolsOfOrigin: null,
    active: null,
    openForm: false,
    openFormTags: false,
    studentTags: [],
    blockStudent: false,
    groups: [],
};

export const studentReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.studentsShow:
            return {
                ...state,
                pagination: action.payload,
            };
        case types.studentsActive:
            return {
                ...state,
                active: action.payload,
            };
        case types.studentsUnsetActive:
            return {
                ...state,
                active: null,
            };
        case types.studentsOpenForm:
            return {
                ...state,
                openForm: true,
            };
        case types.studentsCloseForm:
            return {
                ...state,
                // active: null,
                openForm: false,
            };
        case types.showStudentOnly:
            return {
                ...state,
                paginationStudents: action.payload,
            };
        case types.studentsOpenFormTags:
            return {
                ...state,
                openFormTags: true,
            };
        case types.studentsCloseFormTags:
            return {
                ...state,
                openFormTags: false,
            };
        case types.studentsTags:
            return {
                ...state,
                studentTags: action.payload,
            };

        case types.studentCyclesForceView:
            return {
                ...state,
                active: {
                    ...state.active,
                    cycles: state.active.cycles.map((cycle) => {
                        if (cycle.id === action.payload) {
                            return {
                                ...cycle,
                                pivot: {
                                    ...cycle.pivot,
                                    student_cycle_view_force:
                                        !cycle.pivot.student_cycle_view_force, // Nuevo valor para student_cycle_view_force
                                },
                            };
                        }
                        return cycle;
                    }),
                },
            };

        case types.studentCycleChange:
            return {
                ...state,
                active: action.payload,
            };

        case types.studentGroups:
            return {
                ...state,
                groups: action.payload,
            };

        case types.studentClearGroups:
            return {
                ...state,
                groups: [],
            };
        case types.studentsSchoolsOfOrigin:
            return {
                ...state,
                paginationSchoolsOfOrgin: action.payload,
            };
        case types.studentsCondensedSchoolsOfOrigin:
            return {
                ...state,
                condensedSchoolsOfOrigin: action.payload,
            };
        case types.studentsBlock:
            return {
                ...state,
                blockStudent: action.payload,
            };
        case types.studentsSetCycleStudentsReport:
            return {
                ...state,
                cycleStudentsReport: action.payload,
            };
        case types.studentsClearCycleStudentsReport:
            return {
                ...state,
                cycleStudentsReport: initialState.cycleStudentsReport,
            };
        default:
            return state;
    }
};

//
